<template>
  <Header
      is-home="true"
      title="Who we are"
      lead="Family owned. American made since 1969"
      bg-image="about-banner.jpg"/>
  <section class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-lg-6 order-2">
        <h2 class="display-6">Our History</h2>
        <p class="lead">Jeffery and Cindy Hurst opened and made their first mattress and box spring set over 53 years
          ago in a tiny, one-room building.</p>
        <p>Since that time, Thomasville Bedding Company has continued to manufacture all of its products locally in
          Thomasville, Georgia. The current factory and factory outlet showroom consists of 36,000 square feet and is in
          continuous production year round.</p>
        <p>For more than 53 years we have continued to use the very best components which include real cotton felt in
          each mattress and box spring, providing you with the durability and most comfort a mattress can provide.</p>
        <p>We are widely acclaimed as one of the few bedding and mattress manufacturers that still make custom mattress
          sets to fit any size bed. We happily ship our mattresses all over the United States and would be honored as
          your choice for your next bed.</p>
      </div>
      <div class="col-12 col-lg-5 text-center me-lg-5 mb-5 mb-lg-0">
        <img src="../assets/img/cindy-jeffrey.jpg" alt="" role="presentation" class="img-fluid mx-auto shadow-lg">
      </div>
    </div>
    <div class="divider text-center">
      <img src="../assets/img/divider.svg" alt="" role="presentation" class="img-fluid mx-auto">
    </div>
    <div class="row my-5">
      <div class="col-12">
        <h2 class="display-6">Our Factory</h2>
        <p class="lead my-3">We take great pride in the mattresses and bedding sets we build.</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../assets/img/factory-1.jpg" class="img-fluid mb-2 mb-lg-0" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../assets/img/factory-2.jpg" class="img-fluid mb-2 mb-lg-0" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../assets/img/factory-3.jpg" class="img-fluid mb-2 mb-lg-0" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <img src="../assets/img/factory-4.jpg" class="img-fluid" alt="">
      </div>
    </div>
    <div class="divider text-center">
      <img src="../assets/img/divider.svg" alt="" role="presentation" class="img-fluid mx-auto">
    </div>
    <div class="row justify-content-between align-items-center">
      <div class="col-12 col-lg-7">
        <h2 class="display-6">Our Mission</h2>
        <div>
          <p class="mb-3"><b>We promise to:</b></p>
          <ol class="list-unstyled">
            <li>
              <div class="d-flex">
                <div class="d-inline-block me-2 icon">
                  <i class="fa-light fa-badge-check fa-2xl"></i>
                </div>
                <p>To manufacture the best possible sleep products with
                  superior
                  workmanship and provide exceptional
                  service by utilizing the best components available.</p>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="d-inline-block me-2 icon">
                  <i class="fa-light fa-badge-check fa-2xl"></i>
                </div>
                <p>To achieve these results through superior teamwork and
                  dedicated leadership.</p>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="d-inline-block me-2 icon">
                  <i class="fa-light fa-badge-check fa-2xl"></i>
                </div>
                <p>To be mindful that we exist because of our customers.</p>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div class="col-12 offset-lg-1 col-lg-4 mt-5 mt-lg-0">
        <div class="img-container text-center">
          <img src="../assets/img/mission-img.jpg" class="shadow-lg img-fluid rounded-circle d-inline-block mx-auto"
               alt="Employee building a custom mattress">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'About',
  components: { Header },
  mounted () {
    document.title = 'Thomasville Bedding'
  }
}
</script>

<style scoped lang="scss">
.owners {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%) !important;
}

.divider {
  margin: 80px auto;

  > img {
    max-width: 25em;
  }
}

li {
  line-height: 1.75;
  margin-bottom: 2em;

  .icon {
    color: $red;
  }
}
</style>
